<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueEditor, Quill } from 'vue2-editor'
import ImageResize from 'quill-image-resize-module'

Quill.register('modules/imageResize', ImageResize)

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Multiselect,
    vueDropzone: vue2Dropzone,
    VueEditor,
    Loading,
  },
  data() {
    return {
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
      isLoading: false,
      baseImageUrl: BASE_IMAGE_URL,
      baseImageHeigthUrl: BASE_IMAGE_URL,
      options: [],
      form: {
        status: true,
        content_title: '',
        content_text: '',
        image: '',
        imageHeigth: '',
        inputtag: [],
        is_video: false,
        // is_paid: false,
        video: '',
        videoHeigth: '',
        short_description: '',
        is_send: false,
        notification_title: '',
        notification_text: '',
        type: 'free',
        feat: false,
      },

      optionsStatus: [
        { value: true, text: 'show' },
        { value: false, text: 'hide' },
      ],
      optionsType: [
        { value: true, text: 'Paid' },
        { value: false, text: 'Free' },
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 340,
        maxFilesize: 100,
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      ismanuallyadd: false,
      isEdit: false,
    }
  },
  validations: {
    form: {
      content_title: {
        required,
      },
      content_text: {
        required,
      },
      image: {
        required,
      },
      video: {
        required,
      },
      imageHeigth: {
        required,
      },
      videoHeigth: {
        required,
      },
    },
  },
  mounted() {
    // this.initialUpdateItem()
    this.getContenttags()
    this.form.category = this.$route.query.categoryID || ''
    console.log(this.$route.query)
    console.log(this);
  },
  methods: {
    doAjax() {
      this.isLoading = true
      // simulate AJAX
    },
    onCancel() {
      this.isLoading = false

      // console.log('User cancelled the loader.')
    },
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },
    vfileAdded_high(file) {
      this.form.imageHeigth = file
      this.ismanuallyadd = false
    },
    vfilemanuallyAdded_high(file) {
      this.ismanuallyadd = true
    },

    async handleClickfeatContent(id) {
      // console.log(id._id);
      console.log(id.feat)
      // this.form.feat = id.feat
      console.log('feat', this.form.feat)
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async getContenttags() {
      const data = await axios
        .get('/api/contenttag')
        .then((res) => res.data)
        .catch((err) => {
          this.$toast.error(err.response.data.error.message)
        })

      this.options = data
      console.log('data', this.form)
    },
    async formSubmit() {
      if (this.isEdit == false) {
        if (this.form.video == '') {
          this.$toast.error('Video field must not be empty ')
        } else {
          if (!this.form.is_video) {
            if (!this.form.image) {
            //   this.$toast.error('Image field must not be empty ')
            }
          }

          let id = []
          this.form.inputtag.map((contenttag) => {
            id.push(contenttag._id)
          })
          this.doAjax()

          let body = {
            content_title: this.form.content_title,
            content_text: this.form.content_text,
            short_description: this.form.short_description,
            hashtag: id,
            status: this.form.status,
            is_paid: this.form.is_paid,
            is_video: this.form.is_video,
            type: this.form.type,
            feat: this.form.feat,
          }
          if (this.$route.query.categoryID) {
            body.category = this.$route.query.categoryID
          }

          console.log(body, 'body jaaaa')

          if (!this.isEdit && this.form.is_send) {
            body.is_send = this.form.is_send
            body.notification_title = this.form.notification_title
            body.notification_text = this.form.notification_text
          }

          const data = new FormData()

          if (!this.ismanuallyadd) {
            let img = this.form.image
            data.append('image', img)
            let img_heigth = this.form.imageHeigth
            data.append('imageHeigth', img_heigth)
          }

          if (this.form.video) {
            data.append('video', this.form.video)
          }

          if (this.form.videoHeigth) {
            data.append('videoHeigth', this.form.videoHeigth)
          }

          data.append('data', JSON.stringify(body))
          const queryId = this.$route.query.id
          const request = !this.isEdit
            ? axios.post('/api/subscription/premium/createContent', data)
            : axios.patch(
                `/api/subscription/premium/updateContent/${queryId}`,
                data
              )

          await request
            .then(async (result) => {
              let id

              if (this.isEdit) {
                id = queryId
              } else {
                id = result.data._id
              }

              await axios.post('/api/content/onUpdateContentText', {
                id,
                content_text: this.form.content_text,
              })

              this.onCancel()
              this.$toast.success('Saved successfully')
              this.$router.go(-1)
              // this.$router.push('/contents/contentlist')
            })
            .catch((err) => {
              this.onCancel()
              this.$toast.error(err.response.data.error.message)
            })
        }
      } else {
        // console.log(this.isEdit);
        // console.log('video', this.form.video)
        // console.log(this.form.is_video)
        // if (!this.form.is_video) {
        //   if (!this.form.image) {
        //     // this.$toast.error('Image field must not be empty ')
        //   }
        // }

        let id = []
        this.form.inputtag.map((contenttag) => {
          id.push(contenttag._id)
        })
        this.doAjax()

        let body = {
          content_title: this.form.content_title,
          content_text: this.form.content_text,
          short_description: this.form.short_description,
          hashtag: id,
          status: this.form.status,
          is_paid: this.form.is_paid,
          is_video: this.form.is_video,
          type: this.form.type,
          feat: this.form.feat,
        }
        if (this.$route.query.categoryID) {
          body.category = this.$route.query.categoryID
        }

        console.log(body, 'body jaaaa')

        if (!this.isEdit && this.form.is_send) {
          body.is_send = this.form.is_send
          body.notification_title = this.form.notification_title
          body.notification_text = this.form.notification_text
        }

        const data = new FormData()

        // if (!this.ismanuallyadd) {
        //   let img = this.form.image
        //   data.append('image', img)
        //   let img_heigth = this.form.imageHeigth
        //   data.append('imageHeigth', img_heigth)
        // }

        if (this.form.video) {
          data.append('video', this.form.video)
        }

        if (this.form.videoHeigth) {
          data.append('videoHeigth', this.form.videoHeigth)
        }

        data.append('data', JSON.stringify(body))
        const queryId = this.$route.query.id
        const request = !this.isEdit
          ? axios.post('/api/subscription/premium/createContent', data)
          : axios.patch(
              `/api/subscription/premium/updateContent/${queryId}`,
              data
            )

        await request
          .then(async (result) => {
            let id

            if (this.isEdit) {
              id = queryId
            } else {
              id = result.data._id
            }

            await axios.post('/api/content/onUpdateContentText', {
              id,
              content_text: this.form.content_text,
            })

            this.onCancel()
            this.$toast.success('Saved successfully')
            this.$router.go(-1)
            // this.$router.push('/contents/contentlist')
          })
          .catch((err) => {
            this.onCancel()
            this.$toast.error(err.response.data.error.message)
          })
      }
    },
    // async initialUpdateItem() {
    //   const id = this.$route.query.id
    //   if (!id) return

    //   await axios
    //     .get(`api/subscription/premium/getContentById/${id}`)
    //     .then((res) => {
    //       this.form = { ...this.form, ...res.data }

    //       this.form.inputtag = res.data.hashtag
    //       this.isEdit = true

    //       var file = { size: 123, name: 'Icon', type: 'image/png' }
    //       if (this.form.img_url) {
    //         var url = `${this.baseImageUrl}/${this.form.img_url}`
    //         this.$refs.myVueDropzone.manuallyAddFile(file, url)
    //       }

    //       var fileHeigth = { size: 123, name: 'IconHeigth', type: 'image/png' }
    //       if (this.form.img_heigth_url) {
    //         var urlHeigth = `${this.baseImageHeigthUrl}/${this.form.img_heigth_url}`
    //         this.$refs.myVueDropzoneheigth.manuallyAddFile(
    //           fileHeigth,
    //           urlHeigth
    //         )
    //       }
    //     })
    // },
    // async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
    //   var formData = new FormData()
    //   formData.append('image', file)

    //   await axios
    //     .post('api/content/ckupload_images', formData, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //       },
    //     })
    //     .then((result) => {
    //       let url = result.data.url // Get url from response
    //       Editor.insertEmbed(cursorLocation, 'image', url)
    //       resetUploader()
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
    // },
    backRoute() {
      this.$router.go(-1)
    },
  },
}
</script>
<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Video Content</h4>
      </div>
      <div class="col-xl-6">
        <!-- <button
          class="
            btn btn-primary
            rounded-pill
            width-lg
            float-right
            aligncenter
            ml-2
            mr-2
          "
          :class="form.is_video ? 'unactiveClass' : ''"
          @click=";(form.is_video = false), (form.image = '')"
        >
          Text Content
        </button> -->
      </div>
      <div class="row mt-2" style="height: 100%; width: 100%">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        v-model="$v.form.content_title.$model"
                        placeholder="Enter title..."
                        name="title"
                        type="text"
                        :state="validateState('content_title')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                     
                      label-cols-lg="0"
                    >
                      <label>Video ID</label>
                      <b-form-input
                        id="title"
                        v-model="form.video"
                        placeholder="Enter Video URL Link..."
                        name="title"
                        type="text"
                      ></b-form-input>
                    </b-form-group>

                    <!-- <b-form-group label-cols-lg="0">
                      <label>Short description</label>
                      <b-form-input
                        placeholder="Short description..."
                        v-model="form.short_description"
                      ></b-form-input>
                      </b-form-group> -->

                    <b-form-group label-cols-lg="0">
                      <label>Content</label>

                      <!-- <vue-editor
                        v-model="$v.form.content_text.$model"
                        :editor-options="editorSettings"
                        height="300"
                      ></vue-editor> -->
                      <vue-editor
                        :use-custom-image-handler="true"

                        v-model="form.content_text"
                        :editorOptions="editorSettings"
                        height="300"
                      ></vue-editor>
                    </b-form-group>
                    <!-- <b-form-group label-cols-lg="2" label="Hashtag :">
                      <div>
                        <multiselect
                          v-model="form.inputtag"
                          tag-placeholder="Add this as new tag"
                          placeholder="Search"
                          label="tag_name"
                          track-by="_id"
                          :options="options"
                          :multiple="true"
                        ></multiselect>
                      </div>
                    </b-form-group> -->

                    <b-form-group label-cols-lg="2" label="Status :">
                      <div>
                        <b-form-select
                          v-model="form.status"
                          :options="optionsStatus"
                        ></b-form-select>
                      </div>
                    </b-form-group>
                    <loading
                      :active.sync="isLoading"
                      :can-cancel="false"
                      :on-cancel="onCancel"
                      :is-full-page="true"
                    ></loading>
                    <b-form-group label-cols-lg="2" label="HighLight :">
                      <b-form-checkbox
                        switch
                        class="switchcss"
                        v-model="form.feat"
                        @click.native="handleClickfeatContent(form)"
                      >
                      </b-form-checkbox>
                    </b-form-group>
                    <!-- <b-form-group label-cols-lg="2" label="Type :">
                      <div>
                        <b-form-select
                          v-model="form.is_paid"
                          :options="optionsType"
                        ></b-form-select>
                      </div>
                    </b-form-group> -->
                    <div v-if="!isEdit">
                      <b-form-group label-cols-lg="0">
                        <b-container class="bv-example-row">
                          <b-row>
                            <label style="color: #4b4b5a; font-weight: 400"
                              >Send Notification *:</label
                            >

                            <b-form-checkbox
                              v-model="form.is_send"
                              class="ml-2"
                            ></b-form-checkbox>
                          </b-row>
                        </b-container>
                      </b-form-group>

                      <div v-if="form.is_send">
                        <b-form-group
                          label-cols-lg="2"
                          label="Notification Title:"
                        >
                          <div>
                            <b-form-input
                              v-model="form.notification_title"
                              placeholder="Enter Notification Title"
                            ></b-form-input>
                          </div>
                        </b-form-group>
                        <b-form-group
                          label-cols-lg="2"
                          label="Notification Text:"
                        >
                          <div>
                            <b-form-input
                              v-model="form.notification_text"
                              placeholder="Enter Notification Text"
                            ></b-form-input>
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <b-form-group>
                      <!-- <router-link to="/contents/contentlist"> -->
                      <button
                        type="button"
                        class="btn btn-danger float-right ml-2"
                        @click="backRoute()"
                        >Cancel</button
                      >
                      <!-- </router-link> -->
                      <button type="submit" class="btn btn-primary float-right"
                        >Save</button
                      >
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scope>
.unactiveClass {
  border: 1px solid #5269f785;
  background: #5269f785;
}

.switchcss {
  padding-top: 8px;
}
</style>
